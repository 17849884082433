<template>
  <div>
    <div class="statis_top">
      <!--搜索框1 选择年份-->
      <a-select style="width: 120px" placeholder="请选择年份" v-model:value="theYear" @change="changeYear">
        <a-select-option v-for="(item, index) in yearList" :key="index" :value="item">{{item}}年</a-select-option>
      </a-select>
      <!--搜索框2 选择月份-->
      <a-select style="width: 120px; margin-right: 5px" placeholder="请选择月份" v-model:value="theMonth" @change="handleDate">
        <a-select-option v-for="(item,index) in monthArr" :key="index" :value="item">{{item}}月</a-select-option>
      </a-select>
      <a-button @click="searchWeeklyReportData" class="button" type="primary">
        <template #icon><SearchOutlined /></template>
        搜索周报
      </a-button>
      <a-button @click="exportWeeklyReport" class="button">一键导出近7天数据</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="changeTableData"
    >
      <template #name="{ text }">
        <a>{{ text }}</a>
      </template>
      <template #action="{ text }">
        <a-button type="primary" @click="showDetails(text)">展示周报详情</a-button>
        <a-button @click="exportHistoryReport(text)" style="margin-left: 10px;">导出周报</a-button>
      </template>
    </a-table>
    <a-modal
      title="历史周报详情"
      v-model:visible="visible"
      width="700px"
      :footer="null"
    >
      <a-descriptions title="周报详情">
        <a-descriptions-item label="时间">{{showData.startTime}}-{{showData.endTime}}</a-descriptions-item>
        <a-descriptions-item label="本周垃圾清理总数">{{showData.total_type_num}}</a-descriptions-item>
        <a-descriptions-item label="本周海滩垃圾清理总数">{{showData.type_beach_num}}</a-descriptions-item>
        <a-descriptions-item label="本周海漂垃圾清理总数">{{showData.type_sea_num}}</a-descriptions-item>
        <a-descriptions-item label="本周垃圾清理总重量">{{showData.total_weight}}</a-descriptions-item>
        <a-descriptions-item label="本周海滩垃圾清理总重量">{{showData.type_beach_weight}}</a-descriptions-item>
        <a-descriptions-item label="本周海漂垃圾清理总重量">{{showData.type_sea_weight}}</a-descriptions-item>
        <a-descriptions-item label="本周巡飞次数">{{showData.totalTime}}</a-descriptions-item>
        <a-descriptions-item label="本周拍摄图片数量">{{showData.totalPhotoNum}}</a-descriptions-item>
        <a-descriptions-item label="无人机巡检各区域垃圾覆盖率">{{showData.avgDesity}}‰</a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>

<script>
import { getAnyDate } from "@/tools/datefmt";
import { message } from "ant-design-vue";
import { SearchOutlined } from "@ant-design/icons-vue";
const columns = [
  {
    title: "起始时间", //名称的
    dataIndex: "startTime", //与下面data里面的数据统一起来，显示的
    key: "startTime", //是关键词，建议个dataindex统一起来，key在dataindex之后的话可以不写
    align: "center",
    width: "12%",
  },
  {
    title: "终止时间",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
    width: "12%",
  },
  {
    title: "该周平均垃圾覆盖率(‰)",
    dataIndex: "avgDesity",
    key: "avgDesity",
    align: "center",
    width: "12%",
  },
  {
    title: "清理垃圾总数量",
    dataIndex: "total_type_num",
    key: "total_type_num",
    align: "center",
    width: "12%",
  },
  {
    title: "清理垃圾总重量",
    dataIndex: "total_weight",
    key: "total_weight",
    align: "center",
    width: "12%",
  },
  {
    title: "巡飞次数",
    dataIndex: "totalTime",
    key: "totalTime",
    align: "center",
    width: "12%",
  },
  {
    title: "操作",
    slots: { customRender: "action" },
    key: "action",
    align: "center",
  },
];
const data = [];
export default {
  components: {
    SearchOutlined
  },
  data() {
    return {
      //选择的年份
      theYear: null,
      yearList: [],
      //选择的月份
      theMonth: null,
      monthArr: [],
      startTime: "",
      endTime: "",

      data: data,
      columns: columns,
      pagination: {
        //分页相关
        current: 1,
        pageSize: 8,
        total: 0,
      },
      loading: false,
      visible: false,
      showData: null
    };
  },
  methods: {
    //根据年份查月份
    changeYear() {
      this.theMonth = null;
    },
    //根据年份和月份计算这个月的起始日期
    handleDate() {
      let year = this.theYear * 1;
      let month =  this.theMonth * 1;
      this.startTime = year + "-" + month + "-01";
      if (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10 || month === 12) {
        this.endTime = year + "-" + month + "-31";
      } else if (month === 2) {
        if (year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0)) {
          this.endTime = year + "-" + month + "-29";
        } else {
          this.endTime = year + "-" + month + "-28";
        }
      } else {
        this.endTime = year + "-" + month + "-30";
      }
    },
    //获取列表的周报数据
    searchWeeklyReportData() {
      this.loading = true;
      let url = 'http://39.100.158.75:8080/garbageClean/findWeekChart';
      this.$axios({
        url,
        method: 'GET',
        headers: {
          token: this.$storage.get("userinfo").data.token,
        },
        params: {
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          startTime: this.startTime === "" ? getAnyDate(-6) : this.startTime,
          endTime: this.endTime === "" ? getAnyDate(0) : this.endTime
        }
      }).then(res => {
        this.loading = false;
        message.info(res.data.message);
        if (res.data.data === null) {
          this.data = [];
          this.pagination.total = 0;
        } else {
          this.data = res.data.data.list;
          this.pagination.total = res.data.data.totalPageCount;
        }
      })
    },
    //选择时间导出周报
    exportReport() {
      if (this.startTime === "" || this.endTime === "") {
        message.error("请选择起止时间");
      } else {
        let url = "http://39.100.158.75:8080/garbageClean/exportOneChartWeek";
        window.location.href =
          url +
          "?startTime=" +
          this.startTime +
          "&endTime=" +
          this.endTime +
          "&userId=" +
          this.$storage.get("userinfo").data.userId;
      }
    },
    //导出近7天数据
    exportWeeklyReport() {
      this.startTime = getAnyDate(-6);
      this.endTime = getAnyDate(0);
      this.exportReport();
      this.startTime = "";
      this.endTime = "";
    },

    //换页的时候触发
    changeMessageData(event) {
      this.pagination.current = event.current;
      this.getTableData();
    },
    //展示周报详情
    showDetails(text) {
      this.showData = text;
      this.visible = true;
    },
    //导出历史数据
    exportHistoryReport(text) {
      let url = "http://39.100.158.75:8080/garbageClean/exportOneChartWeek";
      window.location.href =
        url +
        "?startTime=" +
        text.startTime +
        "&endTime=" +
        text.endTime +
        "&userId=" +
        this.$storage.get("userinfo").data.userId;
    },
    //关闭模态框
    closeDetails() {
      this.visible = false;
    },
  },
  mounted() {
    for (let i = 2020; i < 2100; i++) {
      this.yearList.push(i);
    }
    for (let j = 1; j < 13; j++) {
      this.monthArr.push(j);
    }
    this.searchWeeklyReportData();
  }
};
</script>

<style lang="scss" scoped>
:deep().ant-table-tbody > tr > td {
  padding: 10px;
}
.statis_top {
  padding: 10px 5px;
  .button {
    margin-left: 10px;
  }
}
.detail-list {
  li {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 40px;
    span {
      display: inline-block;
      margin-right: 30px;
    }
  }
}
</style>
